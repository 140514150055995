
  import { defineComponent } from 'vue';
  import KTUserMenu from '@/layout/header/partials/UserMenu.vue';

  export default defineComponent({
    name: 'topbar',
    components: {
      KTUserMenu,
    },
  });
