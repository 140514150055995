
  import { defineComponent, onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import KTMenu from '@/layout/aside/Menu.vue';
  import { asideTheme } from '@/core/helpers/config';

  export default defineComponent({
    name: 'KTAside',
    components: {
      KTMenu,
    },
    props: {
      lightLogo: String,
      darkLogo: String,
    },
    setup() {
      const minimaizeMode = ref(true);

      onMounted(() => {
        const savedMinimizeMode = localStorage.getItem('minimizeMode');
        if (savedMinimizeMode) {
          document.body.setAttribute(
            'data-kt-aside-minimize',
            savedMinimizeMode
          );
          minimaizeMode.value = savedMinimizeMode === 'on' ? true : false;
        } else {
          localStorage.setItem('minimizeMode', 'off');
        }
      });
      const { t } = useI18n();

      const toggleMinimaizeMode = () => {
        minimaizeMode.value = !minimaizeMode.value;
        if (minimaizeMode.value) {
          document.body.setAttribute('data-kt-aside-minimize', 'on');
          localStorage.setItem('minimizeMode', 'on');
        } else {
          document.body.setAttribute('data-kt-aside-minimize', 'off');
          localStorage.setItem('minimizeMode', 'off');
        }
      };

      return {
        asideTheme,
        t,
        toggleMinimaizeMode,
        minimaizeMode,
      };
    },
  });
