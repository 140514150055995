
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useRoute } from 'vue-router';
  import { asideMenuIcons } from '@/core/helpers/config';
  import MainMenuConfig from '@/core/config/MainMenuConfig';
  import { useAbility } from '@casl/vue';
  import { useStore } from 'vuex';

  import { Actions } from '@/store/enums/StoreEnums';

  export default defineComponent({
    name: 'kt-menu',
    components: {},

    setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const scrollElRef = ref<null | HTMLElement>(null);
      const { can } = useAbility();
      // let adsCount = ref(0);
      // let appraisalsRequestsCount = ref(0);

      const InlineButtonClickHandler = () => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
      };

      setInterval(function () {
        store.dispatch(Actions.GET_ADS_APPRS_INFO);
      }, 300000);

      onMounted(() => {
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0;
        }
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const hasActiveChildren = (match) => {
        return route.path.indexOf(match) !== -1;
      };
      const checkAbility = (method, resource) => {
        let members = resource instanceof Array ? resource : [resource];
        let valid = false;
        members.some((currentValue) => {
          valid = can(method, currentValue);
          return valid;
        });
        return valid;
      };

      store.dispatch(Actions.GET_ADS_APPRS_INFO);
      // .then(({ data }) => {
      //   // Handle the data here
      //   // console.log(data);

      //   appraisalsRequestsCount.value = data.data['appraisalsRequestsCount'];
      //   adsCount.value = data.data['adsCount'];
      // })
      // .catch((error) => {
      //   // Handle errors here
      //   console.error(error);
      // });

      return {
        appraisalsRequestsCount: computed(
          () => store.getters.getAppraisalsRequestsCount
        ),
        adsCount: computed(() => store.getters.getAdsCount),
        hasActiveChildren,
        MainMenuConfig,
        asideMenuIcons,
        translate,
        can,
        checkAbility,
        InlineButtonClickHandler,
      };
    },
  });
